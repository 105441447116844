// @import '~antd/dist/antd.less';
// @import '~antd/lib/style/themes/default.less';

body,
body * {
  font-family: 'Roboto', sans-serif;
}

.ant-layout {
  background: white;

  &.ant-layout-has-sider > .ant-layout {
    overflow: hidden;

    > .ant-layout-content {
      margin: 0;
      padding: 24px;
      overflow: scroll;
    }
  }
}

.ant-pro-sider-logo {
  padding: 0 !important;
}

.site-page-header {
  padding-top: 6px;
  padding-bottom: 0;
  padding-left: 0;
}

.ant-layout-sider {
  background: #f7f7f7;

  .ant-layout-sider-trigger {
    background: #f7f7f7;
    color: #002766;
  }
}

.carrier-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    margin-right: 10px;
    font-weight: bold;
  }
}

.ant-descriptions-item-container {
  .ant-descriptions-item-content {
    flex-wrap: wrap;
  }
}

.vehicle-tag {
  margin-bottom: 10px;
  border-radius: 10px;
}

.modal-save-btn {
  width: 100%;
}

.modal-close-btn {
  float: right;
}

.city-state-input {
  .ant-input-wrapper.ant-input-group {
    table-layout: fixed;
  }

  .ant-input-group-addon {
    width: 50%;
    padding: 0;
    text-align: left;

    .ant-select {
      margin: -5px 0;
    }
  }

  .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
    width: 100%;
  }
}

.text-center {
  text-align: center;
}

@primary-color: #0050b3;