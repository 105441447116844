.carrier-insurance-panel {
  &__upload-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .ant-upload-list {
      flex: 1 1 auto;

      .ant-upload-list-item {
        margin-top: 0;
      }
    }
  }

  &__insurance-file-info {
    display: inline-flex;
    align-items: center;
    padding-left: 5px;

    a {
      display: inline-block;
      max-width: 400px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 7px;
      margin-right: 20px;
    }
  }

  &__input-number {
    width: 100%;
  }
}

@primary-color: #0050b3;