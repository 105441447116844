.terminal-assign-modal {
  &__terminal-name {
    margin-right: 10px;
  }

  &__terminal-info {
    font-size: 12px;
  }
}

@primary-color: #0050b3;