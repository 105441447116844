.login-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  &__header {
    margin-bottom: 40px;

    img {
      max-width: 260px;
      user-select: none;
    }
  }

  form {
    min-width: 300px;
  }
}

@primary-color: #0050b3;