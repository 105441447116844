.terminal-modal {
  &__textarea {
    resize: none;
  }

  &__switch-label {
    line-height: 32px;
    margin-left: 20px;
  }
}

@primary-color: #0050b3;