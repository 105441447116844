.add-rate {
  &__ship-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  th,
  td {
    padding: 0 5px;
  }
  th {
    text-align: left;
    padding-bottom: 5px;
  }
}

@primary-color: #0050b3;