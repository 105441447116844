.cp-app-list {
  .ant-table-thead {
    th.ant-table-cell {
      background: transparent;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 15px;
    }
  }

  .ant-table-tbody {
    tr {
      td:first-child {
        font-size: 16px;
        font-weight: 500;
        max-width: 160px;
      }
    }
  }
}

@primary-color: #0050b3;