.admin-upload-page {
  &__carrier-select {
    margin-top: 30px;

    & > .ant-select {
      width: 200px;
    }
  }
}

@primary-color: #0050b3;