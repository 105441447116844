.rates-upload {
  &__file-uploader {
    max-height: 450px;
  }

  &__step-button {
    margin-top: 20px;
  }

  &__step-content {
    margin-top: 50px;
  }
}

@primary-color: #0050b3;