.user-modal {
  &__half-width-item {
    width: 50%;
    margin-bottom: 0;
  }
  &__quarter-width-item {
    width: 25%;
    margin-bottom: 0;
  }
}

@primary-color: #0050b3;