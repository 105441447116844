table.routeBuilderList {
  width: 100%;
  tr:nth-child(odd) {
    background-color: #eee;
  }
  tr.preferred td{
    color: green;
  }
  th, td {
    padding: 6px;
    text-align: left;
  }

  td.left, th.left {
    text-align: left;
  }
  td.right, th.right {
    text-align: right;
  }
  td.center, th.center {
    text-align: center;
  }
}
@primary-color: #0050b3;