.contact-table {
  width: 100%;
  background-color: white;

  thead {
    th {
      padding: 10px;
      font-weight: 500;
      background: white;
      font-weight: bold;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d9d9d9;

      td {
        font-size: 12px;
        padding: 10px;
      }
    }
    tr:hover {
      background-color: #eee;
    }
  }

}

@primary-color: #0050b3;