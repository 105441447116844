// @import '~antd/lib/style/themes/default.less';

.ant-pro-sider-logo {
  padding: 0;
}

.ant-pro-sider {
  .ant-pro-sider-logo {
    .logo {
      display: flex;
      height: 50px;
      font-size: 11px;
      background: #002766;
      align-items: center;
      justify-content: center;
      width: 250px;
      padding-left: 6px;
      padding-right: 6px;

      img {
        height: 30px;
        margin-right: 10px;
      }
      h1 {
        color: white;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  .menu-item-upload {
    margin-top: 30px;

    a {
      display: flex;
      height: 40px;
      background: #dfe2e4;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      h1 {
        font-weight: normal;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
      }

      .anticon {
        margin-left: 10px;
        font-size: 16px;
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .ant-pro-sider-logo {
      padding: 0;
    }

    .ant-pro-sider-extra {
      padding: 0;

      .menu-item-upload {
        a {
          h1 {
            display: none;
          }

          .anticon {
            margin: 0;
          }
        }
      }
    }
  }

  .carriers-navigation {
    margin-bottom: 20px !important;

    width: 100%;
    background: transparent;

    .ant-menu-submenu {
      padding: 0 30px;

      .ant-menu-submenu-title {
        background: #dfe2e4;
        border-radius: 4px;
      }

      &.ant-menu-submenu-selected {
        color: black;
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-submenu {
        padding: 0 15px;

        .ant-menu-submenu-title {
          span {
            max-width: 0;
            opacity: 0;
          }

          i {
            display: block;

            &::before {
              transform: rotate(45deg) translateY(-2px);
            }

            &::after {
              transform: rotate(-45deg) translateY(2px);
            }
          }
        }
      }
    }
  }

  .user-profile-section {
    display: flex;
    align-items: center;
    padding: 0 30px;
    margin-bottom: 10px;

    &__user-info {
      margin-left: 10px;

      h5 {
        margin-bottom: 0.2em;
      }

      & > span {
        display: flex;
        align-items: center;
        font-size: 11px;

        & > span {
          text-transform: uppercase;
          color: #aaa;
        }

        button {
          margin-left: 5px;
          padding: 0;
          font-size: 10px;
          height: 100%;
        }
      }
    }
  }

  .version-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 5px;
  }
}

@primary-color: #0050b3;