.class-list {
  &__vehicle-select {
    &.ant-select-disabled.ant-select-multiple {
      .ant-select-selector {
        background: white;
        cursor: text;
        input {
          cursor: text;
        }
      }
      .ant-select-selection-item {
        cursor: text;
      }
    }
  }
}

.clickablelink {
  text-decoration: 'underline';
}

.clickablelink:hover {
  background-color: '#eea';
}

@primary-color: #0050b3;