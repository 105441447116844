.carrier-options-panel {
  &__items-policy {
    width: 100%;

    .ant-space-item {
      display: flex;
    }
    .ant-form-item-control-input-content {
      display: flex;
    }
    .ant-input-group.ant-input-group-compact {
      display: flex;
    }

    > .ant-form-item-control {
      width: 100%;
      overflow: auto;
      padding-bottom: 10px;
    }
  }

  &__fuel-input {
    width: 100px;

    input {
      text-align: right;
    }

    &__date {
      input {
        max-width: 75px;
      }
    }
  }

  &__unit-input {
    width: 70px !important;
  }
}

@primary-color: #0050b3;