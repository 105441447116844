.path-table {
  width: 100%;

  thead {
    th {
      padding: 10px;
      font-weight: 500;
    }
  }

  tbody {
    &.path-table__body {
      border-radius: 2px;
      overflow: hidden;
      box-shadow: 0 0 0 1px black;

      tr {
        border-bottom: 1px solid #d9d9d9;

        &:first-child {
          border-color: black;

          td {
            background: white;
            font-weight: normal;
          }
        }

        &:last-child {
          border: none;
        }

        td {
          padding: 7px 10px;
          background: #f6f6f6;
          font-weight: 300;
          font-size: 12px;
        }
      }
    }

    .preferred {
      color: green;
    }

    &.path-table__separator {
      td {
        height: 15px;
      }
    }
  }
}

@primary-color: #0050b3;