.rate-table.ant-card {
  .ant-card-body {
    padding: 1px 0 0 0;
  }

  .ant-card-extra {
    padding: 0;
  }

  .ant-table-thead > tr > th {
    background: white;
    font-weight: bold;
  }

  .ant-table-container table > thead > tr > th {
    padding: 10px 13px;

    .ant-table-column-sorters {
      padding: 0;
    }
  }

  table {
    width: 100%;

    td {
      .ant-btn-circle.ant-btn-sm {
        min-width: 23px;
        width: 23px;
        height: 23px;
      }

      &:last-child {
        width: 130px;
      }
    }
  }
}

.ant-table-tbody {
  tr.row-preferred {
    color: green;
  }
  tr.row-unpreferred {
    color: orange;
  }
  tr:nth-of-type(even) {
    background-color: #F7F7F7;
    td.alt-column {
      background-color: #EFEFEF;
    }  
  }
 
  tr:hover td {
    background-color: rgba(255, 255, 182)!important;
  }


  td.alt-column {
    background-color: #F7F7F7;

  }
}



@primary-color: #0050b3;