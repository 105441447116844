.path-filter {
  th {
    font-weight: normal;
  }

  &__form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
}

@primary-color: #0050b3;